import React, { useContext } from 'react'
import Table from '../Table'
import Settings from '../Settings'
import Dashboard from '../Dashboard'
import GlobalMonitoring from '../GlobalMonitoring'
import Overview from '../Overview'
import SchoolMonitoring from '../SchoolMonitoring'
import Import from '../Import'
import GlobalContext from '../../contexts/GlobalContext'
import DocumentManager from '../DocumentManager'
import GlobalSettings from '../GlobalSettings'

/**
 * Routeur pour afficher la vue sélectionnée.
 */
const Router = () => {
	const { view } = useContext(GlobalContext);

	const views = {
		import: <Import />,
		overview: <Overview />,
		global_monitoring: <GlobalMonitoring />,
		config: <GlobalSettings />,
		document_manager: <DocumentManager />,
		users: <Table />,
		dashboard: <Dashboard />,
		monitoring: <SchoolMonitoring />,
		params: <Settings />
	};

	return views[view] || <div />;
};

export default Router;